import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ProjectFilters from './ProjectFilters';
import ProjectsList from './ProjectsList';
import ProjectDataService from '../../services/project.service';
import CategoryDataService from '../../services/category.service';
import BookmarkDataService from '../../services/bookmark.service';
import { UserContext } from '../../uses/users.context';
import Typography from '@mui/joy/Typography';

function SearchProjects(props) {
  const { user } = useContext(UserContext);
  const [projects, setProjects] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [categories, setCategories] = useState([]);
  const { brand } = props;

  useEffect(() => {
    retrieveCategories();
    retrieveProjects();
    if (user) {
      BookmarkDataService.findByUser(user.id).then((response) => {
        setBookmarkList(response.data);
      });
    }
    // eslint-disable-next-line
  }, []);

  const retrieveProjects = (category = null, state = null, search = null) => {
    ProjectDataService.findByCategory(category, brand, state, search).then((response) => {
      const tempProjects = ProjectDataService.decrypt(response.data);
      setProjects(tempProjects);
    });
  };

  const retrieveCategories = () => {
    CategoryDataService.getByBrand(brand).then((response) => {
      setCategories(response.data);
    });
  };

  function handleChange(category, state, search) {
    retrieveProjects(category, state, search);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        level='h1'
        marginTop={5}
        marginBottom={2}
        textAlign={'center'}
        sx={{ marginX: { xs: 5, sm: 15, md: 30, lg: 40, xl: 60 } }}
      >
        Finançons l&apos;arme la plus puissante du monde : l&apos;Éducation
      </Typography>
      <ProjectFilters categories={categories} onChange={handleChange} />
      <ProjectsList
        titleKey='all_projects'
        projects={projects}
        user={user}
        brand={brand}
        bookmarkList={bookmarkList}
        setBookmarkList={setBookmarkList}
      />
    </Box>
  );
}

export default SearchProjects;
