import { MangopayVault } from '@mangopay/vault-sdk';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, LoadingButton } from '@mui/lab';
import { CircularProgress, Grid, FormControlLabel } from '@mui/material';
import { getTotalPriceTTC } from '../../utils/number';

import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import FormHelperText from '@mui/joy/FormHelperText';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';

import { PaymentSummary } from './PaymentSummary';
import UserService from '../../services/user.service';
import CartService from '../../services/cart.service';
import { UserContext } from '../../uses/users.context';
import { IMaskInput } from 'react-imask';

const CardMaskAdapter = React.forwardRef(function CardMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='0000 0000 0000 0000'
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange(value)}
      overwrite
    />
  );
});

const CardExpiryMaskAdapter = React.forwardRef(function CardExpiryMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00/00'
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange(value)}
      overwrite
    />
  );
});

export function CheckoutForm(props) {
  const { t } = useTranslation();
  const { items, taxes } = props;
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentReady] = useState(true);
  const { user } = useContext(UserContext);

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardCvx, setCardCvx] = useState('');
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    setIsLoading(true);
    setMessage(null);

    const validationErrors = {};

    if (!agreementAccepted) {
      validationErrors.agreementAccepted = 'Vous devez accepter les conditions.';
    }
    if (!cardNumber || cardNumber === '') {
      validationErrors.cardNumber = 'Le numéro de carte est requis.';
    }
    if (!cardExpirationDate || cardExpirationDate === '') {
      validationErrors.cardExpirationDate = "La date d'expiration est requise.";
    }
    if (!cardCvx || cardCvx === '') {
      validationErrors.cardCvx = 'Le code de sécurité est requis.';
    }
    if (cardNumber.replace(/\s/g, '').length !== 16) {
      validationErrors.cardNumber = 'Le numéro de carte est invalide.';
    }
    if (
      cardExpirationDate &&
      (cardExpirationDate.length !== 5 || !cardExpirationDate.match(/^[0-9]{2}\/[0-9]{2}$/) === null)
    ) {
      validationErrors.cardExpirationDate = "La date d'expiration est invalide. Format : MM/AA";
    }
    if (cardCvx && (isNaN(cardCvx) || cardCvx.length !== 3)) {
      validationErrors.cardCvx = 'Le code de sécurité est invalide.';
    }

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Create mangopay card registration on backend
        const createCardRegistrationResult = await UserService.postCardRegistration(user.id, {
          cardType: 'CB_VISA_MASTERCARD',
          currency: 'EUR',
        });

        const options = {
          clientId: process.env.REACT_APP_MANGOPAY_CLIENT_ID,
          environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'PRODUCTION' : 'SANDBOX',
        };

        const vault = MangopayVault.initialize(options);

        const cardInfoObject = {
          cardNumber: cardNumber.replace(/\s/g, ''),
          cardExpirationDate: cardExpirationDate.replace('/', ''),
          cardCvx: cardCvx,
        };

        const preregistrationData = {
          id: createCardRegistrationResult.data.Id,
          cardRegistrationURL: createCardRegistrationResult.data.CardRegistrationURL,
          accessKeyRef: createCardRegistrationResult.data.AccessKey,
          data: createCardRegistrationResult.data.PreregistrationData,
        };

        // Tokenize card with Mangopay Vault SDK
        const { CardId: cardId } = await vault.tokenizePaymentMethod(cardInfoObject, preregistrationData);

        const payinData = {
          cardId,
          colorDepth: window.screen.colorDepth,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        };

        // Create mangopay payin on backend
        const createPayinResult = await CartService.checkout(user.id, payinData);

        if (createPayinResult.data.type === 'success') {
          if (createPayinResult.data.data.secureModeNeeded) {
            // Redirect to 3DSecure page
            window.location.href = createPayinResult.data.data.secureModeRedirectURL;
          } else {
            // Redirect to success page
            window.location.href = createPayinResult.data.data.secureModeReturnUrl;
          }
        } else {
          setMessage('Une erreur est survenue lors du paiement. Veuillez réessayer plus tard.');
        }
      } catch (e) {
        console.error(e);
        // Mangopay related errors
        if (e.ResultCode === '105202') {
          validationErrors.cardNumber = 'Le numéro de carte est invalide.';
        } else if (e.ResultCode === '105203') {
          validationErrors.cardExpirationDate = "La date d'expiration est invalide.";
        } else if (e.ResultCode === '105204') {
          validationErrors.cardCvx = 'Le code de sécurité est invalide.';
        }

        if (e.response) {
          if (e.response.status === 500) {
            setMessage('Une erreur est survenue lors du paiement. Veuillez réessayer plus tard.');
          }
        }
      }
    }
    setErrors(validationErrors);
    setIsLoading(false);
  };

  return (
    <div className='container'>
      <CircularProgress style={{ margin: '50px auto', display: `${!paymentReady ? 'block' : 'none'}` }} />
      <div
        style={{
          display: `${paymentReady ? 'flex' : 'none'}`,
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '25px',
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={8}>
            <div style={{ padding: '0 20px' }}>
              <Grid justifyContent='center' item xs={12} md={8}>
                <h1>{t('payment')}</h1>
              </Grid>
              <Grid item xs={12} md={8} justifyContent='center'>
                <form>
                  <Grid
                    item
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
                      gap: 1.5,
                    }}
                  >
                    <FormControl required={true} sx={{ gridColumn: '1/-1' }} error={!!errors.cardNumber}>
                      <FormLabel>{t('card_number')}</FormLabel>
                      <Input
                        value={cardNumber}
                        endDecorator={<CreditCardIcon />}
                        name={'cardNumber'}
                        placeholder='1234 1234 1234 1234'
                        slotProps={{ input: { component: CardMaskAdapter } }}
                        onChange={(value) => setCardNumber(value)}
                      />
                      {errors.cardNumber && (
                        <FormHelperText>
                          <InfoOutlined />
                          {errors.cardNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl required error={!!errors.cardExpirationDate}>
                      <FormLabel>{t('card_expiry_date')}</FormLabel>
                      <Input
                        value={cardExpirationDate}
                        endDecorator={<CreditCardIcon />}
                        name={'cardExpirationDate'}
                        onChange={(value) => setCardExpirationDate(value)}
                        placeholder='MM/AA'
                        slotProps={{ input: { component: CardExpiryMaskAdapter } }}
                      />
                      {errors.cardExpirationDate && (
                        <FormHelperText>
                          <InfoOutlined />
                          {errors.cardExpirationDate}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl required error={!!errors.cardCvx}>
                      <FormLabel>{t('card_cvx')}</FormLabel>
                      <Input
                        type='number'
                        value={cardCvx}
                        endDecorator={<LockIcon />}
                        name={'cardCvx'}
                        onChange={(e) => setCardCvx(e.target.value.substring(0, 3))}
                        placeholder='CVV'
                      />
                      {errors.cardCvx && (
                        <FormHelperText>
                          <InfoOutlined />
                          {errors.cardCvx}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      required
                      error={!!errors.agreementAccepted}
                      style={{ marginTop: '50px', gridColumn: '1/-1', my: 1 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreementAccepted}
                            checked={agreementAccepted}
                            onChange={(e) => setAgreementAccepted(e.target.checked)}
                            name='agreementAccepted'
                          />
                        }
                        label={
                          <div
                            dangerouslySetInnerHTML={{ __html: t('agree_therms_payment') }}
                            style={{ paddingLeft: '20px' }}
                          />
                        }
                      />
                      {errors.agreementAccepted && (
                        <FormHelperText>
                          <InfoOutlined />
                          {t('agree_therms_payment_error')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <LoadingButton
                    className='btn-primary'
                    sx={{
                      margin: '3rem 0',
                      padding: '.5rem 1.5rem !important',
                      display: 'flex',
                    }}
                    loadingPosition='end'
                    endIcon={<span className='material-icons'>navigate_next</span>}
                    onClick={() => handleSubmit()}
                    loading={isLoading}
                    fullWidth
                  >
                    {t('valid_and_pay')} {getTotalPriceTTC(items, taxes)}€
                  </LoadingButton>
                  {message && (
                    <Alert severity='error' style={{ margin: '10px 0 0 0' }}>
                      {message}
                    </Alert>
                  )}
                </form>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <PaymentSummary items={items} taxes={taxes} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
