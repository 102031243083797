import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { Select, Option } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/joy/Input';

function ProjectFilters({ onChange, categories }) {
  const [category, setCategory] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    onChange(category, state, search);
    // eslint-disable-next-line
  }, [category, state, search]);

  function DebounceInput(props) {
    const { handleDebounce, debounceTimeout, ...rest } = props;

    const [value, setValue] = React.useState(search);
    const timerRef = React.useRef();

    const handleChange = (event) => {
      const newValue = event.target.value;
      setValue(newValue);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        handleDebounce(newValue);
      }, debounceTimeout);
    };

    return <Input {...rest} value={value} onChange={handleChange} />;
  }

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} marginTop={5}>
        <Grid item alignContent={'center'}>
          <span>Filtrer</span>
        </Grid>

        {/* Select category */}
        <Grid item>
          <Select
            value={category}
            onChange={(e, newValue) => setCategory(newValue)}
            placeholder='Toutes les catégories'
            {...(category && {
              // display the button and remove select indicator
              // when user has selected a value
              endDecorator: (
                <IconButton
                  size='xs'
                  variant='plain'
                  color='neutral'
                  onMouseDown={(event) => {
                    // don't open the popup when clicking on this button
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    setCategory(null);
                  }}
                >
                  <CloseRounded />
                </IconButton>
              ),
              indicator: null,
            })}
            sx={{
              width: 200,
            }}
          >
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <Option value={category.id}>{category.name}</Option>
              </React.Fragment>
            ))}
          </Select>
        </Grid>

        {/* Select state */}
        <Grid item>
          <Select
            value={state}
            onChange={(e, newValue) => setState(newValue)}
            placeholder='Toutes les campagnes'
            {...(state && {
              // display the button and remove select indicator
              // when user has selected a value
              endDecorator: (
                <IconButton
                  size='xs'
                  variant='plain'
                  color='neutral'
                  onMouseDown={(event) => {
                    // don't open the popup when clicking on this button
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    setState(null);
                  }}
                >
                  <CloseRounded />
                </IconButton>
              ),
              indicator: null,
            })}
            sx={{
              width: 200,
            }}
          >
            <Option value={'prelaunch'}>À venir</Option>
            <Option value={'in_progress'}>En cours</Option>
            <Option value={'finished'}>Terminées</Option>
          </Select>
        </Grid>

        {/* Search input */}
        <Grid item>
          <DebounceInput
            debounceTimeout={1000}
            handleDebounce={(value) => setSearch(value)}
            startDecorator={<SearchIcon />}
            placeholder='Rechercher'
            value={search}
            sx={{
              minWidth: 300,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProjectFilters;
