import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import Header from '../../components/Header';
import { AfterPayment } from '../../components/payment/AfterPayment';
import SEO from '../../components/SEO';

export default function AfterPaymentIndex(props) {
  const { items, active } = props;
  const [searchParams] = useSearchParams();
  const params = useParams();

  const paymentStatus = searchParams.get('status');
  const meta = {
    title:
      paymentStatus === 'SUCCEEDED'
        ? 'Merci pour votre contribution - My Moojo'
        : 'Erreur lors de votre contribution - My Moojo',
    description:
      paymentStatus === 'SUCCEEDED'
        ? 'Merci pour votre contribution sur My Moojo.'
        : 'Une erreur est survenue lors de votre contribution sur My Moojo.',
    meta: {
      property: {
        'og:title':
          paymentStatus === 'SUCCEEDED'
            ? 'Merci pour votre contribution - My Moojo'
            : 'Erreur lors de votre contribution - My Moojo',
        'og:description':
          paymentStatus === 'SUCCEEDED'
            ? 'Merci pour votre contribution sur My Moojo.'
            : 'Une erreur est survenue lors de votre contribution sur My Moojo.',
      },
    },
  };

  return (
    <main>
      <SEO title={meta.title} description={meta.description} name='My Moojo' image='https://mymoojo.com/logo.png' type='website' url='https://mymoojo.com/search' />
      <Header items={items(params)} active={active} />
      <AfterPayment transactionId={searchParams.get('transactionId')} />
    </main>
  );
}
