import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Box, Button, IconButton, Toolbar, Container } from '@mui/material';
import '../css/_header.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { themeContext } from '../uses/theme.uses';
import { UserContext } from '../uses/users.context';
import CartDataService from '../services/cart.service';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';

function Header(props) {
  const { theme } = useContext(themeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  let { items, active } = props;
  const params = useParams();
  const tm = useTheme();
  const mobile = useMediaQuery(tm.breakpoints.down('sm'));
  const [tempCart, setTempCart] = useState(false);

  const parseActive = (params) => {
    if (!active) {
      return null;
    }
    for (const key in params) {
      active = active.replace(`:${key}`, params[key]);
    }
    return active;
  };

  useEffect(() => {
    CartDataService.getCart(user?.id ?? -1).then((response) => {
      setTempCart(response.data.length);
    });
  }, [user]);

  return (
    <AppBar position='static' className='app-header'>
      <Container maxWidth='xl'>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: { sm: 2, md: 4 } }}>
              <img src={theme.images.logo} alt='MyMoojo' onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
            </Box>

            {!mobile &&
              items &&
              items.map((item, index) => (
                <a
                  href={item.to}
                  className={`menu-link ${parseActive(params) === item.to ? 'active' : ''}`}
                  key={index}
                >
                  <div>{t(item.name)}</div>
                </a>
              ))}
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {!mobile && (
              <Box sx={{ flexGrow: 1, display: 'inline-flex', justifyContent: 'flex-end' }}>
                {((!theme.noConnection || theme?.noConnection === false) && user && (
                  <Button
                    style={{ margin: '0 10px' }}
                    sx={{ display: { xs: 'none', sm: 'inherit' }, lineHeight: 'inherit' }}
                    className='btn-primary'
                    onClick={() => navigate('/dashboard/projects')}
                  >
                    {t('menu_dashboard')}
                  </Button>
                )) || (
                  <>
                    <Button
                      sx={{ display: { xs: 'none', sm: 'inherit' }, lineHeight: 'inherit' }}
                      className='btn-grey'
                      onClick={() => navigate('/login')}
                    >
                      {t('log_in')}
                    </Button>
                    <Button
                      style={{ margin: '0 10px' }}
                      sx={{ display: { xs: 'none', sm: 'inherit' }, lineHeight: 'inherit' }}
                      className='btn-primary'
                      onClick={() => navigate('/signup')}
                    >
                      {t('create_new_project')}
                    </Button>
                  </>
                )}
              </Box>
            )}

            {tempCart && tempCart > 0 ? (
              <Link style={{ marginRight: '10px' }} to='/cart'>
                <IconButton aria-label='cart'>
                  <Badge
                    badgeContent={tempCart}
                    style={{ color: theme.palette.secondary.main, verticalAlign: 'middle' }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Link>
            ) : null}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
