import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cancel as CancelIcon, UploadFileOutlined } from '@mui/icons-material';

export function FileUpload({
  onChange,
  onDelete,
  error,
  multiple,
  suggestedSize,
  acceptedTypes,
  title,
  file,
  fileName,
  preview,
}) {
  const { t } = useTranslation();

  const acceptedExtension = acceptedTypes.map((type) => ' ' + type.split('/')[1]);

  return (
    <label
      className='custom-file-upload'
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderColor: error ? '#d32f2f' : 'rgba(0,0,0,0.23)',
      }}
    >
      <Box p={'15px'} alignItems={'center'} display={'flex'}>
        {preview && file && (file instanceof File || file.startsWith('http')) && (
          <Grid item paddingRight={'50px'}>
            <div style={{ position: 'relative' }}>
              <Avatar
                id='avatarProfile'
                alt=''
                src={file instanceof File ? URL.createObjectURL(file) : file}
                sx={{ width: '100px', height: '100px', fontSize: '4rem' }}
              ></Avatar>
              <IconButton
                aria-label='delete'
                style={{ position: 'absolute', top: '-5px', right: '-5px' }}
                onClick={onDelete}
              >
                <CancelIcon />
              </IconButton>
            </div>
          </Grid>
        )}

        <UploadFileOutlined fontSize='large' />
        <Grid container>
          {(fileName && (
            <Typography variant='body2' px={1}>
              {fileName}
            </Typography>
          )) || (
            <Typography color={'#05A488'} fontWeight={'600'} px={1}>
              {title || t('select_picture_project')}
            </Typography>
          )}
          <Grid container>
            {acceptedExtension && (
              <Typography variant='body2' px={1}>
                {t('select_picture_tip_1') + ' ' + acceptedExtension}
              </Typography>
            )}
            {suggestedSize && (
              <Typography variant='body2' px={1}>
                {t('select_picture_tip_2') + ' ' + suggestedSize}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      <input
        type='file'
        id='projectImage'
        name='projectImage'
        multiple={multiple || false}
        required
        onChange={onChange}
        accept={acceptedTypes}
      />
    </label>
  );
}
