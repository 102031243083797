import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { ThemeDataProvider } from '../routes/ThemeDataProvider';
import Legal from './Legal';
import Faq from './Faq';
import Support from './Support';
import {
  AdminCategoriesIndex,
  AdminFeaturedItemsIndex,
  AdminFeaturedUsersIndex,
  AdminParametersIndex,
  AdminPresentationFieldsIndex,
  AdminProjectsIndex,
  AdminUsersIndex,
  AdminDashboardIndex,
  AdminTasksIndex,
  DashboardIndex,
  ListOfProjects,
  LoginIndex,
  SignupIndex,
  PasswordResetIndex,
  ContributionsIndex,
  ProfileIndex,
  ProjectEdit,
  ProjectsIndex,
  UserAccountIndex,
  UserProjectsIndex,
} from '../routes';
import { ProtectedRoute } from '../routes/ProtectedRoute';
import ProjectPackCreate from '../routes/projects/projectPackCreate';
import PaymentIndex from '../routes/payments/payment';
import AfterPaymentIndex from '../routes/payments/afterPayment';
import PasswordReset from './auth/PasswordReset';
import { CancelPasswordReset } from './auth/CancelPasswordReset';
import LoginLinkedIn from './auth/LoginLinkedIn';
import CartIndex from '../routes/users/cart';
import AdminBrandsIndex from '../routes/admin/brands';
import { BrandChecker } from '../routes/BrandChecker';
import packageJson from '../../package.json';

import '../css/_index.scss';
import '../i18n';
import SentryRoutes from '../utils/sentry';
import ProtectedLayout from './ProtectedLayout';
import ProjectsUser from './user/ProjectsUser';
import ProjectDashboard from '../routes/projects/projectDashboard';
import ProjectSubscribers from '../routes/projects/projectSubscribers';
import ProjectContributions from '../routes/projects/projectContributions';
import ProjectForfait from '../routes/projects/projectForfait';
import SegmentService from '../services/segment.service';

function App() {
  const params = useParams();
  let location = useLocation();

  useEffect(() => {
    SegmentService.load();
  }, []);

  useEffect(() => {
    SegmentService.page();
  }, [location]);

  const items = (params = null) => [
    {
      name: 'our_projects',
      to: !params.brand ? '/search' : `/${params.brand}`,
    },
  ];

  const adminItems = (params = null) => {
    const root = !params.brand ? '' : `/${params.brand}`;
    return [
      {
        name: 'projects',
        to: `${root}/admin/projects`,
      },
      {
        name: 'users',
        to: `${root}/admin/users`,
      },
      {
        name: 'categories',
        to: `${root}/admin/categories`,
      },
      {
        name: 'parameters',
        to: `${root}/admin/parameters`,
      },
      {
        forBrand: false,
        name: 'brands',
        to: `${root}/admin/brands`,
      },
      {
        name: 'presentation_fields',
        to: `${root}/admin/presentation_fields`,
      },
      {
        forBrand: false,
        name: 'featured_items',
        to: `${root}/admin/featured_items`,
      },
      {
        forBrand: false,
        name: 'featured_users',
        to: `${root}/admin/featured_users`,
      },
      {
        forBrand: false,
        name: 'tasks',
        to: `${root}/admin/tasks`,
      },
    ];
  };

  const caching = () => {
    const version = localStorage.getItem('version');

    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  caching();

  return (
    <SentryRoutes>
      {/* Basic MyMoojo website */}
      <Route path='' element={<ThemeDataProvider />}>
        {/* Redirect to search page if homepage */}
        <Route index element={<Navigate to='/search' />} />

        {/* Dashboard routes */}
        <Route path='dashboard' element={<ProtectedLayout />}>
          <Route
            index
            element={
              <ProtectedRoute
                path='/dashboard/account'
                element={<DashboardIndex items={items(params)} active={null} />}
              />
            }
          />
          <Route path='account' element={<ProtectedRoute path='/dashboard/account' element={<UserAccountIndex />} />} />
          <Route
            path='contributions'
            element={<ProtectedRoute path='/dashboard/contributions' element={<ContributionsIndex />} />}
          ></Route>
          <Route path='projects'>
            <Route index element={<ProtectedRoute path='/dashboard/projects' element={<ProjectsUser />} />} />
            <Route
              path=':id'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id'
                  element={<ProjectDashboard items={items} active={null} />}
                />
              }
            />
            <Route
              path=':id/forfait'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/forfait'
                  element={<ProjectForfait items={items} active={null} />}
                />
              }
            />
            <Route
              path=':id/edit'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/edit'
                  element={<ProjectEdit items={items} active={null} />}
                />
              }
            />
            <Route
              path=':id/subscribers'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/subscribers'
                  element={<ProjectSubscribers items={items} active={null} />}
                />
              }
            />
            <Route
              path=':id/contributions'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/contributions'
                  element={<ProjectContributions items={items} active={null} />}
                />
              }
            />
            <Route
              path=':id/contribution'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/contribution'
                  element={<ProjectPackCreate title='add_a_contribution' />}
                />
              }
            />
            <Route
              path=':id/contribution/:packId'
              element={
                <ProtectedRoute
                  path='/dashboard/projects/:id/contribution/:packId'
                  element={<ProjectPackCreate title='edit_a_contribution' />}
                />
              }
            />
          </Route>
        </Route>

        {/* Old routes to redirect */}
        <Route
          path='/dashboard/*'
          element={<Navigate replace to={window.location.pathname.replace('/dashboard', '')} />}
        />

        {/* Basic routes */}
        <Route path='search' element={<ListOfProjects items={items} active='/search' />} />

        {/* Footer routes */}
        <Route path='legal' element={<Legal items={items} active={null} />} />
        <Route path='faq' element={<Faq items={items} active={null} />} />
        <Route path='support' element={<Support items={items} active={null} />} />

        {/* Project routes */}
        <Route path='project/:slug' element={<ProjectsIndex items={items} active={null} />} />

        {/* Payment routes */}
        <Route
          path='cart'
          element={<ProtectedRoute path='/cart' element={<CartIndex items={items} active={null} />} />}
        />
        <Route path='payment' element={<PaymentIndex items={items} active={null} />} active={null} />
        <Route
          path='after_payment'
          element={<ProtectedRoute path='/after_payment' element={<AfterPaymentIndex items={items} active={null} />} />}
          active={null}
        />

        {/* Auth routes */}
        <Route path='login' element={<LoginIndex items={items} active={null} />} />
        <Route path='signup' element={<SignupIndex items={items} active={null} />} />
        <Route path='passwordReset'>
          <Route index element={<PasswordResetIndex items={items} active={null} />} />
          <Route path=':id/:token' element={<PasswordReset items={null} active={null} />} />
          <Route path='cancel/:id/:token' element={<CancelPasswordReset />} />
        </Route>
        <Route path='auth/linkedin' element={<LoginLinkedIn />} />

        {/* User routes */}
        <Route path='user'>
          <Route path=':id' element={<ProfileIndex items={items} active={null} />} />
        </Route>

        {/* Administration routes */}
        <Route path='admin'>
          <Route
            index
            element={
              <ProtectedRoute
                path='/admin'
                element={<AdminDashboardIndex adminItems={adminItems} items={items} active='/admin' />}
              />
            }
          />
          <Route
            path='projects'
            element={
              <ProtectedRoute
                path='/admin/projects'
                element={<AdminProjectsIndex adminItems={adminItems} items={items} active='/admin/projects' />}
              />
            }
          />
          <Route
            path='tasks'
            element={
              <ProtectedRoute
                path='/admin/tasks'
                element={<AdminTasksIndex adminItems={adminItems} items={items} active='/admin/tasks' />}
              />
            }
          />
          <Route
            path='categories'
            element={
              <ProtectedRoute
                path='/admin/categories'
                element={<AdminCategoriesIndex adminItems={adminItems} items={items} active='/admin/categories' />}
              />
            }
          />
          <Route
            path='users'
            element={
              <ProtectedRoute
                path='/admin/users'
                element={<AdminUsersIndex adminItems={adminItems} items={items} active='/admin/users' />}
              />
            }
          />
          <Route
            path='parameters'
            element={
              <ProtectedRoute
                path='/admin/parameters'
                element={<AdminParametersIndex adminItems={adminItems} items={items} active='/admin/parameters' />}
              />
            }
          />
          <Route
            path='brands'
            element={
              <ProtectedRoute
                path='/admin/brands'
                element={<AdminBrandsIndex adminItems={adminItems} items={items} active='/admin/brands' />}
              />
            }
          />
          <Route
            path='presentation_fields'
            element={
              <ProtectedRoute
                path='/admin/presentation_fields'
                element={
                  <AdminPresentationFieldsIndex
                    adminItems={adminItems}
                    items={items}
                    active='/admin/presentation_fields'
                  />
                }
              />
            }
          />
          <Route
            path='featured_items'
            element={
              <ProtectedRoute
                path='/admin/featured_items'
                element={
                  <AdminFeaturedItemsIndex adminItems={adminItems} items={items} active='/admin/featured_items' />
                }
              />
            }
          />
          <Route
            path='featured_users'
            element={
              <ProtectedRoute
                path='/admin/featured_users'
                element={
                  <AdminFeaturedUsersIndex adminItems={adminItems} items={items} active='/admin/featured_users' />
                }
              />
            }
          />
        </Route>
      </Route>

      {/* Branded website */}
      <Route exact path='/:brand' element={<BrandChecker />}>
        <Route index element={<App items={items} active='/:brand' />} />
        <Route path='search' element={<ListOfProjects items={items} active='/:brand/search' />} />
        {/* Project routes */}
        <Route path='project'>
          <Route index element={<UserProjectsIndex items={items} active={null} />} />
          <Route path='edit/:id' element={<ProjectEdit items={items} active={null} />} />
          <Route path=':id' element={<ProjectsIndex items={items} active={null} />} />
        </Route>

        <Route path='user'>
          <Route path='cart' element={<CartIndex items={items} active={null} />} />
        </Route>

        {/* Payment routes */}
        <Route path='payment' element={<PaymentIndex items={items} />} active={null} />
        <Route
          path='after_payment'
          element={<ProtectedRoute path='/after_payment' element={<AfterPaymentIndex items={items} />} />}
          active={null}
        />

        {/* Administration routes */}
        <Route path='admin'>
          <Route index element={<AdminDashboardIndex adminItems={adminItems} items={items} active='/:brand/admin' />} />
          <Route
            path='projects'
            element={<AdminProjectsIndex adminItems={adminItems} items={items} active='/:brand/admin/projects' />}
          />
          <Route
            path='categories'
            element={<AdminCategoriesIndex adminItems={adminItems} items={items} active='/:brand/admin/categories' />}
          />
          <Route
            path='users'
            element={<AdminUsersIndex adminItems={adminItems} items={items} active='/:brand/admin/users' />}
          />
          <Route
            path='presentation_fields'
            element={
              <AdminPresentationFieldsIndex
                adminItems={adminItems}
                items={items}
                active='/:brand/admin/presentation_fields'
              />
            }
          />
        </Route>
      </Route>

      {/* 404 NOT FOUND */}
      <Route path='*' element={<Navigate to='/?notFound' />} />
      {/*</Routes>*/}
    </SentryRoutes>
  );
}

export default App;
