import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import i18n from '../../../i18n';
import React, { useContext, useEffect, useState } from 'react';
import { themeContext } from '../../../uses/theme.uses';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import { ImageUpload } from '../../ImageUpload';

export default function CreatePackForm(props) {
  const { pack, setPack, callback, projectId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deliveryModes = [
    {
      value: 'shipping',
      label: t('reward_delivery_shipping_label'),
      help: t('reward_delivery_shipping_help'),
    },
    { value: 'in_person', label: t('reward_delivery_in_person_label'), help: t('reward_delivery_in_person_help') },
    { value: 'immaterial', label: t('reward_delivery_immaterial_label'), help: t('reward_delivery_immaterial_help') },
    { value: 'none', label: t('reward_delivery_none_label'), help: t('reward_delivery_none_help') },
  ];

  const validateShippingCost = (value) => {
    if (pack.deliveryMode && pack.deliveryMode === 'shipping') {
      if (!value || value.length <= 0) {
        return [t('mandatory_field')];
      }
      if (Number(value) < 0) {
        return [t('field_value_must_be_greater_than') + ' 0'];
      }
    }
    return [];
  };

  const validatePickupInstructions = (value) => {
    if (pack.deliveryMode && pack.deliveryMode === 'in_person' && (!value || value.length <= 0)) {
      return [t('mandatory_field')];
    }
    return [];
  };

  const [errors, setErrors] = useState({
    title: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (value.length > 80) {
          return [`${t('field_must_not_exceed')} 80 ${t('characters')}`];
        }
        return [];
      },
    },
    description: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (value.length > 500) {
          return [`${t('field_must_not_exceed')} 500 ${t('characters')}`];
        }
        return [];
      },
    },
    quantity: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && (!Number.isInteger(Number(value)) || Number(value) <= 0)) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    price: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && Number(value) <= 0) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    oldPrice: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && Number(value) < 0) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    deliveryMode: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (!deliveryModes.map((e) => e.value).includes(value)) {
          return [t('invalid_field_value')];
        }
        return [];
      },
    },
    shippingCost: {
      error: [],
      getErrors: validateShippingCost,
    },
    pickupInstructions: {
      error: [],
      getErrors: validatePickupInstructions,
    },
    delivery: [
      {
        error: [],
        getErrors: (value) => {
          if (!value || value.length <= 0) {
            return [t('mandatory_field')];
          }
          if (value < 1 || value > 12) {
            return [`${t('field_value_must_be_between')} 1 ${t('and')} 12`];
          }
          return [];
        },
      },
      {
        error: [],
        getErrors: (value) => {
          if (!value || value.length <= 0) {
            return [t('mandatory_field')];
          }
          if (value < Number(new Date().getFullYear())) {
            return [`${t('field_value_must_be_greater_than')} ${new Date().getFullYear()}`];
          }
          return [];
        },
      },
    ],
  });

  const { theme } = useContext(themeContext);
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  // Mettez à jour les erreurs de validation pour shippingCost et pickupInstructions chaque fois que pack.deliveryMode change
  useEffect(() => {
    const updateErrors = () => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        shippingCost: {
          ...prevErrors.shippingCost,
          getErrors: (v) => validateShippingCost(v),
        },
        pickupInstructions: {
          ...prevErrors.pickupInstructions,
          getErrors: (v) => validatePickupInstructions(v),
        },
      }));
    };

    // Appelez la fonction de mise à jour des erreurs chaque fois que pack.deliveryMode change
    updateErrors();
    // eslint-disable-next-line
  }, [pack.deliveryMode]);

  const getMonthName = (monthNumber) => {
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, monthNumber - 1, 1);
    const monthName = date.toLocaleDateString(i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en', { month: 'long' });

    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const checkForm = () => {
    let error = false;
    const tempErrors = Object.keys(errors).reduce((acc, key) => {
      let _errors;
      if (Array.isArray(errors[key])) {
        acc[key] = errors[key].map((element, elementIndex) => {
          _errors = 'getErrors' in element ? element.getErrors(pack[key][elementIndex]) : [];
          return {
            ...element,
            error: _errors,
          };
        });
      } else {
        _errors = 'getErrors' in errors[key] ? errors[key].getErrors(pack[key]) : [];
        acc[key] = {
          ...errors[key],
          error: _errors,
        };
      }
      if (Array.isArray(_errors) && _errors.length > 0) {
        error = true;
      }
      return acc;
    }, {});
    setErrors(() => tempErrors);
    return !error;
  };

  return (
    <Grid container sx={{ p: 2 }} direction='column' justifyContent='center' alignItems='center' spacing={5}>
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('description_counterpart')}
          </Typography>
          <Tooltip title={t('title_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' } }}
            label={t('title')}
            placeholder={t('add_title')}
            fullWidth
            value={pack.title}
            error={errors.title.error.length > 0}
            helperText={errors.title.error.join('<br>')}
            onChange={(e) => setPack((curr) => ({ ...curr, title: e.target.value }))}
            InputProps={{ endAdornment: <InputAdornment position='end'>80</InputAdornment> }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' } }}
            label={t('description')}
            placeholder={t('add_description')}
            fullWidth
            value={pack.description}
            error={errors.description.error.length > 0}
            helperText={errors.description.error.join('<br>')}
            onChange={(e) => setPack((curr) => ({ ...curr, description: e.target.value }))}
            multiline
            rows={4}
            InputProps={{ endAdornment: <InputAdornment position='end'>500</InputAdornment> }}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('counterpart_picture')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageUpload
            image={pack.image}
            onChange={(event) => setPack((curr) => ({ ...curr, image: event.target.files[0] }))}
            onDelete={() => setPack((curr) => ({ ...curr, image: null }))}
            suggestedSize='426x240 pixels'
            acceptedTypes={['image/jpeg', 'image/png', 'image/gif']}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('available_quantity')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={'number'}
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' }, min: 0 }}
            fullWidth
            label={t('quantity')}
            placeholder={t('unlimited')}
            value={pack.quantity}
            error={errors.quantity.error.length > 0}
            helperText={
              errors.quantity.error.length > 0 ? errors.quantity.error.join('<br>') : t('let_empty_for_unlimited')
            }
            onChange={(e) => setPack((curr) => ({ ...curr, quantity: e.target.value }))}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('contribution_price')}
          </Typography>
          <Tooltip title={t('crossed_out_price_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: sm || md ? 'row' : 'column' }}>
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' } }}
              label={t('contribution_price')}
              placeholder='0'
              fullWidth
              InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
              value={pack.price}
              error={errors.price.error.length > 0}
              helperText={errors.price.error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, price: e.target.value }))}
            />
            <div
              style={{
                width: sm || md ? '1.5rem' : '100%',
                height: sm || md ? '100%' : '1.5rem',
              }}
            />
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' } }}
              label={`${t('crossed_out_price')} (${t('optional')})`}
              placeholder='0'
              fullWidth
              InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
              value={pack.oldPrice}
              error={errors.oldPrice.error.length > 0}
              helperText={errors.oldPrice.error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, oldPrice: e.target.value }))}
            />
          </div>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('delivery_mode')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset' fullWidth error={errors.deliveryMode.error.length}>
            <FormGroup>
              {deliveryModes.map((mode) => (
                <Grid
                  key={mode.label}
                  xs={12}
                  style={{
                    border: '1px solid #ccc',
                    padding: '8px',
                    borderRadius: '4px',
                    marginBottom: '8px',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: '-5px',
                    }}
                    slotProps={{
                      typography: { sx: { width: '100%' } },
                    }}
                    control={
                      <Checkbox
                        checked={pack.deliveryMode === mode.value}
                        onChange={() => {
                          setPack((curr) => ({ ...curr, deliveryMode: mode.value }));
                        }}
                        name={mode.label}
                      />
                    }
                    style={{ alignItems: 'flex-start', width: '100%' }}
                    label={
                      <Grid container xs={12} direction={'column'}>
                        <Typography variant='body1' style={{ fontWeight: '600' }}>
                          {mode.label}
                        </Typography>
                        {mode.help && <Typography variant='caption'>{mode.help}</Typography>}
                        {pack.deliveryMode === 'shipping' && mode.value === 'shipping' && (
                          <Grid xs={12} mt={2}>
                            <TextField
                              InputProps={{
                                endAdornment: <InputAdornment position='start'>€</InputAdornment>,
                              }}
                              label='Montant des frais de livraison'
                              error={errors.shippingCost.error.length > 0}
                              helperText={errors.shippingCost.error.join('<br>')}
                              type='number'
                              value={pack.shippingCost}
                              onChange={(e) => setPack((curr) => ({ ...curr, shippingCost: e.target.value }))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant='outlined'
                            />
                          </Grid>
                        )}
                        {pack.deliveryMode === 'in_person' && mode.value === 'in_person' && (
                          <Grid xs={12} mt={2}>
                            <TextField
                              label='Indiquer où et quand récupérer la contrepartie'
                              error={errors.pickupInstructions.error.length > 0}
                              helperText={errors.pickupInstructions.error.join('<br>')}
                              minRows={3}
                              fullWidth
                              multiline
                              value={pack.pickupInstructions}
                              onChange={(e) => setPack((curr) => ({ ...curr, pickupInstructions: e.target.value }))}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                </Grid>
              ))}
            </FormGroup>
            {errors.deliveryMode.error.length > 0 && (
              <FormHelperText>{errors.deliveryMode.error.join('<br>')}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('estimated_delivery')}
          </Typography>
          <Tooltip title={t('delivery_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: sm || md ? 'row' : 'column' }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en-gb'}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                inputProps={{ sx: { color: '#000' } }}
                label={t('month')}
                fullWidth
                select
                value={pack.delivery[0] ?? 0}
                error={errors.delivery[0].error.length > 0}
                helperText={errors.delivery[0].error.join('<br>')}
                onChange={(e) => setPack((curr) => ({ ...curr, delivery: [e.target.value, curr.delivery[1]] }))}
              >
                {Array.from({ length: 12 }, (_, index) => index + 1).map((i) => (
                  <MenuItem key={i} value={i}>
                    {getMonthName(i)}
                  </MenuItem>
                ))}
              </TextField>
            </LocalizationProvider>
            <div
              style={{
                width: sm || md ? '1.5rem' : '100%',
                height: sm || md ? '100%' : '1.5rem',
              }}
            />
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' }, min: new Date().getFullYear() }}
              label={t('year')}
              fullWidth
              value={pack.delivery[1]}
              error={errors.delivery[1].error.length > 0}
              helperText={errors.delivery[1].error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, delivery: [curr.delivery[0], e.target.value] }))}
            />
          </div>
        </Grid>
      </Grid>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem',
        }}
      >
        <Button
          className='btn-grey'
          style={{ border: 'solid red !important' }}
          onClick={() => navigate(`/project/${projectId}`)}
        >
          {t('back')}
        </Button>
        <div
          style={{
            width: sm || md ? '1.5rem' : '100%',
            height: sm || md ? '100%' : '1.5rem',
          }}
        />
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              callback(pack);
            }
          }}
        >
          {t('save')}
        </Button>
      </div>
    </Grid>
    // </Container>
  );
}
